.workoutbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 300px;
  margin: auto;
  margin-top: 15px;
  color: white;
  border-radius: 2%;
  background-image: -webkit-linear-gradient(353deg, #361134, #ea3788);
  background-image: -o-linear-gradient(353deg, #361134, #ea3788);
  background-image: linear-gradient(97deg, #361134, #ea3788);
  box-shadow: 5px 5px 5px #353333;
}

@media screen and (max-width: 600px) {
  .workoutbox {
    background-color: rgb(141, 66, 97);
    color: white;
    background-image: -webkit-linear-gradient(353deg, #324376, #f76c5e);
    background-image: -o-linear-gradient(353deg, #324376, #f76c5e);
    background-image: linear-gradient(97deg, #324376, #f76c5e);
    box-shadow: none;
  }
}
@media screen and (max-width: 300px) {
  .workoutbox {
    width: 250px;
  }
}

.workoutbox h4 {
  margin: 0;
}
.workoutbox button {
  border-radius: 2%;
}
.workoutbox a {
  color: black;
  text-decoration: none;
}

.deleletey,
.workoutbox a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 100px;
  background-color: #f391a0;
  font-size: 1rem;
  border: 0.2px solid black;
  font-weight: bold;
}
.deleletey:hover,
.workoutbox a:hover {

  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .deleletey,
  .workoutbox a {
    background-color: #f9bb9f;
    color: black;
  }
}

.wwR {
  padding-top: 24px;
  font-size: 16px;
  color: white;

  @media screen and (max-width: 600px) {
    .wwR {
      color: #f391a0;
    }
  }
}

.date{
  display: flex;
  color: black;
}
