header {
  height: 78px;
  width: 100%;
  background: black;
  padding: 0;
}

.header-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.header-wrapper div,
.header-wrapper nav {
  width: 30%;
}
.header-wrapper div h1 {
  color: #d16169;
  margin: 0;
}

.header-wrapper nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #d16169;
}
.header-wrapper nav a {
  text-decoration: none;
  color: #d16169;
  font-size: 1rem;
}
.header-wrapper nav button {
  text-decoration: none;
  background: none;
  color: #d16169;
  cursor: pointer;
  font-size: 1rem;
  border: none;
}
.header-wrapper nav button:hover,
.header-wrapper nav a:hover {
  color: white;
  font-size: 1rem;
}

@media screen and (max-width: 800px) {
  .header-wrapper {
    display: none;
  }
  header {
    height: 200px;
    padding-bottom: 3rem;
  }
}


.header-wrapper-mobile nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-wrapper-mobile div h1 {
  font-size: 1.5rem;
  margin: 0;
  background-image: -webkit-linear-gradient(353deg, #324376, #f76c5e);
  background-image: -o-linear-gradient(353deg, #324376, #f76c5e);
  background-image: linear-gradient(97deg, #324376, #f76c5e);
}
.header-wrapper-mobile nav button,
.header-wrapper-mobile nav a {
  color: #d16169;
  max-width: 100px;
}

.header-wrapper-mobile nav a {
  text-decoration: none;
  color: #d16169;
  font-size: 1rem;
  padding: 5px 0;
}
.header-wrapper-mobile nav button {
  text-decoration: none;
  background: none;
  color: #d16169;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  padding: 5px 0;
}

.closedMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.closedMenu > * {
  height: 8px;
  width: 48px;
  margin: 2px 0;
  background-image: -webkit-linear-gradient(353deg, #324376, #f76c5e);
  background-image: -o-linear-gradient(353deg, #324376, #f76c5e);
  background-image: linear-gradient(97deg, #324376, #f76c5e);
}

@media screen and (min-width: 800px) {
  .header-wrapper-mobile {
    display: none;
  }
}
