.password-box {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rebeccapurple;
    background-image: url("/src/gym-background-home.jpg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    
    margin: auto;
  }

  .authitems {
    margin-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #361134;
      box-shadow: 5px 5px 5px #353333;;
      border-radius: 2%;
      
      
      padding: 15px;
      
    }
    @media screen and (max-width: 600px) {
        .authitems{
            background-color:black;
            color: white;
            font-size: 3em;
            box-shadow: none;
            border-radius: 0;
        }
    } 
  .loginbox {
    height: 250px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  .authbox button {
    background-color: #F391A0;
    cursor: pointer;
  }
   form button {
    background-color: #F391A0;
    cursor: pointer;
  }
  @media screen and (max-width: 600px) {
    .authbox button{
        background-color: #F9BB9F ;
    }
  } 
  @media screen and (max-width: 600px){
  .authbox ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: white;
  }}
  @media screen and (max-width: 600px) {
    .authbox input {
      height: 30px;
      color: white;
      background-image: -webkit-linear-gradient(353deg, #324376, #f76c5e);
      background-image: -o-linear-gradient(353deg, #324376, #f76c5e);
      background-image: linear-gradient(189deg, #324376, #f76c5e);
    }
  }
  
  .auth-btn-cont {
    height: 75px;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .auth-btn-cont a {
    color:#F391A0;
  }