 .App {
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;

 }
body {
    color: white;
    /* background-color: rgb(128, 59, 134); */
    background-image: -webkit-linear-gradient(353deg,#E56B70,#361134);
    background-image: -o-linear-gradient(353deg,#E56B70,#361134);
    background-image: linear-gradient(97deg,#E56B70,#361134);
}

@media screen and (max-width: 800px){
    body {
        background-image: -webkit-linear-gradient(353deg, #324376, #f76c5e);
    background-image: -o-linear-gradient(353deg, #324376, #f76c5e);
    background-image: linear-gradient(97deg, #324376, #f76c5e);
       
    }
}
@media screen and (max-width: 600px){
    body {
        background-color:black;color: white;
        background-image: none
       
    }
}

