.metgoals {
  color: white;
  text-align: center;
  font-size: 30px;
  height: 50px;
  width: 150px;
  margin: 20px;
  background-color: #361134;
  border-radius: 12%;
  transition-duration: 0.4s;
}
.metgoals:hover {
  color: #F391A0;
  background-color: #b0228c;
}

@media screen and (max-width: 600px) {
  .metgoals {
    font-size: 30px;
    height: 50px;
    width: 150px;
    color: white;
    background-image: -webkit-linear-gradient(353deg, #324376, #f76c5e);
    background-image: -o-linear-gradient(353deg, #324376, #f76c5e);
    background-image: linear-gradient(97deg, #324376, #f76c5e);
  }
}
@media screen and (max-width: 600px) {
  .metgoals:hover {
    font-size: 30px;
    height: 50px;
    width: 150px;
    color: white;
    background-image: -webkit-linear-gradient(353deg, #f76c5e , #324376);
    background-image: -o-linear-gradient(353deg,#f76c5e , #324376);
    background-image: linear-gradient(97deg, #f76c5e , #324376);
  }
}
.goalsMet {
  color: white;
  text-align: center;
  font-size: 30px;
  height: 50px;
  width: 150px;
  margin: 20px;
  background-color: #361134;
  border-radius: 12%;
  transition-duration: 0.4s;
}
.goalsMet:hover {
  color: #F391A0;
  background-color: #b0228c;
}
